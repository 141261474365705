<!-- 确认报价页面 -->
<template>
  <div class="con-container">
    <header-area :stepActive="4" @getGroupInfo="getGroupInfo"></header-area>
    <!-- <div class="gap"></div> -->
    <div class="borderTop" v-for="(item, i) in conList" :key="i">
      <div class="con-tit">
        <div style="display: flex;align-items: center;">
          <span class="tit-index">{{ i + 1 }}</span>
          <img v-if="item.shopLogo" :src="item.shopLogo" class="tit-img" />
        </div>
        <div class="titHeader">
          <div style="display: flex;align-items: center;">
            <span class="tit-name">{{item.shopName}}</span>
            <el-tag type="success" v-if="item.serviceType == 1" class="tit-tag">{{"普通客服"}}</el-tag>
            <el-tag type="success" v-if="item.serviceType == 2" class="tit-tag blue">{{"金牌客服"}}</el-tag>
            <el-tag type="success" v-if="item.serviceType == 3" class="tit-tag orange">{{"临时客服"}}</el-tag>
          </div>
          <div class="shop-web-platform">
            <!-- <img src="item" class="shop-icon" /> -->
            <span class="tit-de">{{item.platformName || '--'}}/{{item.categoryName || '--'}}</span>
            <span class="website">{{item.shopUrl || '--'}}</span>
          </div>
        </div>
        <div class="tit-childrenNum" style="display: flex;">
          <div class="tit-child">
            <span>协议客服工作时长</span>
            <span class="tit-num">{{item.workHour || '0'}}</span>
          </div>
          <div class="tit-child">
            <span>协议子账号数量</span>
            <span class="tit-num">{{item.subaccountNum || '0'}}</span>
          </div>
          <div class="tit-child2">
            <span>协议子账号日咨询量</span>
            <span class="tit-num">{{item.shopConsultationNum || '0'}}</span>
          </div>
          <div class="titDays">
            <span class="tit-days">{{item.serviceDay || "0"}}天</span>
            <span class="tit-date"> {{item.startTime}} ~ {{item.endTime}}</span>
          </div>
        </div>
      </div>
      <div class="con-body">
        <div class="conBd">
          <span class="Bd-span">约定数据标准</span>
          <div class="con-item">
            <div class="con-hd">
              <span>【{{item.dataStandard.type == 'base' ? '基础版' : item.dataStandard.type == 'register' ? '专业版' : '旗舰版'}}】</span>
            </div>
            <div class="con-bd">
              <ul>
                <li><span>首响</span> <span class="bd-data">{{item.dataStandard['首响'] || '0'}}s</span></li>
                <li><span>平响</span> <span class="bd-data">{{item.dataStandard['平响'] || '0'}}s</span></li>
                <li><span>转换率</span> <span class="bd-data">{{item.dataStandard['转换率'] || '0'}}%</span></li>
                <li><span>三分钟回复率</span> <span class="bd-data">{{item.dataStandard['三分钟回复率'] || '0'}}%</span></li>
                <li><span>五分钟回复率</span> <span class="bd-data">{{item.dataStandard['五分钟回复率'] || '0'}}%</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="conBd">
          <span class="Bd-span">配置服务内容</span>
          <div v-for="(itemTwo, indexTwo) in item.serviceContent" :key="indexTwo">
            <div class="body-item" v-if="itemTwo.isChoose == 1">
              <div class="one-tit">
                <span class="one-tit-header">【{{itemTwo.step == 0 ? '售前' : itemTwo.step == 1 ? '售中' : itemTwo.step == 2 ? '售后' : '附加类'}}】服务内容</span>
              </div>
              <div class="one-con">
                <ul>
                  <li>
                    <span class="li-one">目录</span>
                    <span class="li-two">基础功能</span>
                    <span class="li-three">登记</span>
                    <span class="li-four">通知</span>
                  </li>
                  <li v-for="(itemThree, indexThree) in itemTwo.value" :key="indexThree" class="li-item">
                    <span class="li-one">{{itemThree.name || "--"}}</span>
                    <span class="li-two twoColor"><i class="el-icon-check"></i></span>
                    <span class="li-three twoColor"><i v-if="itemThree.isRegist == 1" class="el-icon-check"></i></span>
                    <span class="li-four twoColor"><i v-if="itemThree.isNotice == 1" class="el-icon-check"></i></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wechat-group-container borderTop">
        <span class="wechat-title">配置服务对接群</span>
        <span>服务对接群单价</span>
        <span style="color: #f24433">{{groupInfo.serviceGroupPrice || 0}}</span>
        <span>元/个，服务对接群数量</span>
        <span style="color: #f24433">{{groupInfo.serviceGroupNum || 0}}</span>
        <span>个，共</span>
        <span style="color: #f24433">{{( Number(groupInfo.serviceGroupPrice) * Number(groupInfo.serviceGroupNum)).toFixed(2)}}</span>
        <span>元。</span>
    </div>
    <div style="height: 100px"></div>
    <div class="sixth">
      <el-button type="primary" @click="() => { saveConfirmQuoted() }">确认报价</el-button>
      <el-button plain class="sett-return" @click="() => { $router.push(`/quoted/appoint?id=${$route.query.id}`) }">上一步：约定数据标准</el-button>
      <span class="sett-total">{{(price).toFixed(2)}}</span>
      <span class="sett-rmb">¥</span>
      <span class="sett-money">配置费用：</span>
    </div>
  </div>
</template>

<script>
import { confirmQuoted, calculationPrice, saveConfirmQuoted } from "../../service/quoted.js"
import HeaderArea from "./components/header.vue"
export default {
  name: "confirmPage",
  components: {
    HeaderArea
  },
  props: {},
  data() {
    return {
      groupInfo: {},
      checked: true,
      radio: true,
      conList: [],
      price: 0
    };
  },
  mounted() {
    this.confirmQuoted()
    this.calculationPrice()
  },
  methods: {
    getGroupInfo(info) {
      this.groupInfo = info
    },
    async saveConfirmQuoted() {
      await saveConfirmQuoted({id: this.$route.query.id})
      this.$router.push(`/quoted/complete?id=${this.$route.query.id}`)
    },
    async calculationPrice() { // 计算价格
      let { data } = await calculationPrice({id: this.$route.query.id})
      this.price = data.price
    },
    async confirmQuoted () { // 确认报价-详情
      let { data } = await confirmQuoted({id: this.$route.query.id})
      data.map((item) => {
        item.dataStandard = JSON.parse(item.dataStandard)
        item.serviceContent = JSON.parse(item.serviceContent)
      })
      this.conList = data
    }
  },
};
</script>

<style lang="less" scoped>
.con-container {
  .wechat-group-container {
    padding: 50px 100px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    align-items: center;
    .wechat-title {
      color: #6F706F;
      margin-right: 48px;
    }
  }
  .borderTop {
    border-top: 16px solid #eee;
  }
  .shop-web-platform {
    display: flex;
    align-items: center;
  }
  .header {
    box-sizing: border-box;
    padding: 25px 127px 32px 100px;
    border-bottom: 1px solid rgba(217, 217, 217, 100);
    .con-title {
      display: flex;
      .tit-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 330px;
        .address {
          // margin-top: 23px;
          font-size: 22px;
        }
        .el-icon-location {
          font-size: 22px;
          margin-right: 8px;
          color: #1890ff;
        }
        .name {
          font-size: 28px;
        }
        .status {
          box-sizing: border-box;
          background-color: #f2f9ff;
          color: #1890ff;
          border: 1px solid #1890ff;
          border-radius: 2px;
          padding: 4px 6px;
          margin-left: 24px;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .tit-center {
        display: flex;
        .center-fir,
        .center-sec {
          display: flex;
          line-height: 42px;
          .center-fir-margin {
            margin-left: 72px;
          }
          .center-fir-third {
            // margin-right: 57px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 6px;
              left: 104px;
              display: inline-block;
              width: 1px;
              height: 72px;
              background: #bbb;
            }
          }
          .center-fir-con {
            display: inline-block;
            height: 42px;
          }
        }
        .sec-margin {
          margin-left: 110px;
        }
        .sec-width {
          width: 250px;
          text-align: left;
        }
        .center-sec-icon {
          margin-right: 16px;
        }
        .center-sec-con {
          display: inline-block;
          // margin-top: 23px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
  .step {
    position: relative;
    box-sizing: border-box;
    margin-top: 48px;
    // height: 149px;
    // line-height: 149px;
  }
  .gap {
    height: 16px;
    background: #eee;
    margin: 32px 0 0;
  }
  .con-tit {
    display: flex;
    border-bottom: 1px solid rgba(181, 209, 255, 100);
    padding: 30px 134px;
  }
  .tit-index {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 28px;
    color: #fff;
    border-radius: 50%;
    background: #1890ff;
    margin-right: 34px;
  }
  .tit-img {
    width: 72px;
    height: 72px;
    margin: 0 20px 0 0;
    border-radius: 50%;
  }
  .tit-name {
    color: rgba(51, 51, 51, 100);
    font-size: 24px;
    margin-right: 40px;
  }
  .tit-tag {
    width: 80px;
    height: 27px;
    line-height: 27px;
    text-align: center;
  }
  .blue {
    border: #1890FF solid 1px !important;
    color: #1890FF;
    background: #f2f9ff;
  }
  .orange {
    border: #F09009 solid 1px !important;
    color: #F09009;
    background: #FFF8ED;
  }
  .shop-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
  }
  .tit-de {
    margin-right: 32px;
    white-space: nowrap;
  }
  .website {
    font-size: 16px;
    max-width: 68%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tit-childrenNum {
    margin-left: 196px;
  }
  .tit-num {
    font-size: 28px;
  }
  .titHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
  }
  .tit-child,
  .tit-child2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 78px;
    position: relative;
  }
  .tit-child2 {
    margin-right: 50px;
    &::after {
      position: absolute;
      top: 17px;
      right: -50px;
      content: "";
      display: inline-block;
      width: 1px;
      height: 64px;
      background: #bbbbbb;
    }
  }
  .tit-days {
    color: rgba(24, 144, 255, 100);
    font-size: 42px;
  }
  .tit-date {
    font-size: 14px;
  }
  .titDays {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 39px;
    margin-bottom: 20px;
  }
  .con-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 0 0 106px;
    margin-bottom: 80px;
    .body-item {
      width: 304px;
      height: 530px;
      border: 1px solid #1890ff;
      border-radius: 4px;
      margin-top: 0px;
      margin: 0 37px 0 0;
      padding: 24px;
      .one-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .one-tit-header {
          font-size: 18px;
        }
      }
      .one-con {
        ul {
          li {
            text-align: left;
          }
        }
        .li-item {
          margin-top: 14px;
        }
        .li-one {
          display: inline-block;
          text-align: left;
          width: 120px;
        }
        .li-two {
          display: inline-block;
          text-align: center;
          width: 90px;
        }
        .twoColor {
          color: #1890ff;
        }
        .li-three {
          display: inline-block;
          text-align: center;
          width: 35px;
          margin-right: 20px;
        }
        .li-four {
          display: inline-block;
          text-align: center;
          width: 35px;
        }
      }
    }
  }
  .conBd {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    padding-top: 32px;
    .con-item {
      width: 337px;
      height: 339px;

      border: 1px solid #1890ff;
      border-radius: 4px;
      &:nth-child(-n + 2) {
        margin-right: 170px;
      }
      .con-hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        background: #f2f9ff;
        height: 75px;
        border-radius: 4px;
        border-bottom: 1px solid #1890ff;
        span {
          font-size: 18px;
        }
      }
      .con-bd {
        padding: 0 24px;
        ul {
          li {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
          }
        }
      }
      .bd-data {
        color: #1890ff;
      }
    }
  }
  .Bd-span {
    margin-right: 24px;
  }
  .body-item {
    width: 304px;
    height: 530px;
    border: 1px solid #1890ff;
    border-radius: 4px;
    margin-top: 40px;
    padding: 24px;
    .one-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .one-tit-header {
        font-size: 18px;
      }
    }
    .one-con {
      ul {
        li {
          text-align: left;
        }
      }
      .li-item {
        margin-top: 14px;
      }
      .li-one {
        display: inline-block;
        text-align: left;
        width: 120px;
      }
      .li-two {
        display: inline-block;
        text-align: center;
        width: 90px;
      }
      .twoColor {
        color: #1890ff;
      }
      .li-three {
        display: inline-block;
        text-align: center;
        width: 35px;
        margin-right: 20px;
      }
      .li-four {
        display: inline-block;
        text-align: center;
        width: 35px;
      }
    }
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 32px 0;
  }
  .sixth {
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 101px;
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-top: 16px solid #eee;
  }
  .sett-money {
    margin-left: 771px;
    margin-right: 15px;
  }
  .sett-rmb {
    color: #1890ff;
    margin-right: 10px;
  }
  .sett-total {
    color: #1890ff;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status {
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return {
    border-color: #1890ff;
    color: #1890ff;
    margin-right: 16px;
  }
}
</style>
